var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"helper.provinces","resource":"","creatable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"d-flex px-2",attrs:{"col":"12"}},[_c('TButtonDelete',{on:{"click":function($event){return _vm.removeProvince(item.id)}}}),_c('TButtonShow',{on:{"click":function($event){return _vm.clickShowDistrict(item)}}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.name,"noTranslate":"","editable":""},on:{"change":function($event){return _vm.$store.dispatch('helper.provinces.update', {
              id: item.id,
              attributes: { name: $event },
            })}}})],1)]}},{key:"country",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.country_id,"noTranslate":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"country-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.country_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "country_id", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('SModalCreateAddress',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}}),_c('SModalShowDistrict',{attrs:{"show":_vm.showModalDistrict,"id":_vm.getId},on:{"update:show":function($event){_vm.showModalDistrict=$event},"update:id":function($event){_vm.getId=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }